export default [
  'trello-green-first',
  'trello-yellow-first',
  'trello-orange-first',
  'trello-red-first',
  'trello-purple-first',
  'trello-green-second',
  'trello-yellow-second',
  'trello-orange-second',
  'trello-red-second',
  'trello-purple-second',
  'trello-green-third',
  'trello-yellow-third',
  'trello-orange-third',
  'trello-red-third',
  'trello-purple-third',
  'trello-blue-first',
  'trello-indigo-first',
  'trello-lime-first',
  'trello-pink-first',
  'trello-grey-first',
  'trello-blue-second',
  'trello-indigo-second',
  'trello-lime-second',
  'trello-pink-second',
  'trello-grey-second',
  'trello-blue-third',
  'trello-indigo-third',
  'trello-lime-third',
  'trello-pink-third',
  'trello-grey-third',

  'berry-red',
  'pumpkin-orange',
  'lagoon-blue',
  'pink-tulip',
  'light-mud',
  'orange-peel',
  'bright-moss',
  'antique-blue',
  'dark-granite',
  'lagune-blue',
  'sunny-grass',
  'morning-sky',
  'light-orange',
  'midnight-blue',
  'tank-green',
  'gun-metal',
  'wet-moss',
  'red-burgundy',
  'light-concrete',
  'apricot-red',
  'desert-sand',
  'navy-blue',
  'egg-yellow',
  'coral-green',
  'light-cocoa',
];
